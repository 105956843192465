import Heading from '@/components/cms/Heading';

const HeadingWrapper = ({ blok }) => {
  const { ElementType, HeadingAppearance, Text } = blok;

  return (
    <Heading variant={HeadingAppearance} element={ElementType}>
      {Text}
    </Heading>
  );
};

export default HeadingWrapper;
