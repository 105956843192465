const HEADING_ELEMENTS = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6'
} as const;

const HEADING_APPEARANCES = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6'
} as const;

const Heading = ({ children, element = 'h2', variant = 'h2', ...rest }) => {
  const Component = HEADING_ELEMENTS[element] || 'h2';
  const asVariant = HEADING_APPEARANCES[variant] || 'h3';

  return (
    <Component className={`u-${asVariant}`} {...rest}>
      <>{children}</>
    </Component>
  );
};

export default Heading;
